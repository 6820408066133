import React from 'react'
import './App.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import HomePage from './components/pages/HomePage';
import Contact from './components/pages/Contact';
import About from './components/pages/About';
import Products from './components/pages/Products';
import NavBarFix from './components/NavBarFix';
import {BrowserRouter, Route} from 'react-router-dom';
import Portfolio from './components/pages/Portfolio';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Navbar />
        <NavBarFix />
        <Route exact path='/' component={HomePage} />
        <Route path='/about' component={About} />
        <Route path='/contact' component={Contact} />
        <Route path='/products' component={Products} />
        <Route path='/portfolio' component={Portfolio} />
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
