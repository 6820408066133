import React from 'react'
import ShopItems from '../shop/ShopItems'


function Products() {
    return (
        <>
           <ShopItems /> 
        </>
    )
}

export default Products
