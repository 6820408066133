import React, { Component } from 'react'

class ProjectThumbnail extends Component {
    render() {
    return (
        <div className="item-project-container"> 
            <div className="img-container">
                <a href={this.props.link}>
                    <img src={this.props.src} alt="NOPE" />
                </a>
            </div>
            <h2>{this.props.title}</h2>
            <p>{this.props.description}</p>
            <a href={this.props.link}><button className="view-more">View More</button></a>
        </div>
    )
  }
}

export default ProjectThumbnail
