import React from 'react'
import ProjectThumbnail from './ProjectThumbnail';
import './Projects.css'

function Projects() {
    return (
        <div className="project-container">
            
            <ProjectThumbnail 
            title="GameJam - Arti-Packed art assets" 
            description="Artwork I created for a 2 day game jam. It was fun doing some more organic sculpts and making artifacts!" 
            src="https://cdna.artstation.com/p/assets/images/images/034/447/264/large/isaac-koerbin-highresscreenshot00006.jpg?1612310599" 
            link="https://www.artstation.com/artwork/Xn99zD"    
            />

            <ProjectThumbnail 
            title="Environment Art - Deathstar hallway" 
            description="Environment art of the Deathstar hallway from Star Wars. Created with just 1 trim sheet in Substance Designer" 
            src="https://cdnb.artstation.com/p/assets/images/images/022/179/099/large/isaac-koerbin-highresscreenshot00005.jpg?1574397431" 
            link="https://www.artstation.com/artwork/zAGAmQ"    
            />

            <ProjectThumbnail 
            title="Environment Art - Crait" 
            description="Environment art of the Crait battle from Star Wars. Uses 2 photoscanned cliff rocks" 
            src="https://cdna.artstation.com/p/assets/images/images/024/154/652/large/isaac-koerbin-highresscreenshot00000.jpg?1581486346" 
            link="https://www.artstation.com/artwork/N5O4Xb"    
            />

            <ProjectThumbnail 
            title="Artwork - Blade Runner 2049 Police Spinner" 
            description="Artwork of the police spinner vechile from Blade Runner 2049. Modelled it Blender, textured in Substance Painter. Based on orthographic representations of the model." 
            src="https://cdna.artstation.com/p/assets/images/images/023/663/646/large/isaac-koerbin-highresscreenshot00005.jpg?1579937673" 
            link="https://www.artstation.com/artwork/XBQlbn"    
            />



            <ProjectThumbnail 
            title="Environment Art - Last of Us 2 Fanart" 
            description="Fan Art from one of the Seraphite controlled areas in The Last Of Us 2. This was made using some old assets that never made it to an asset package, where I tested out a night time scene with raindrops on all foliage types." 
            src="https://cdnb.artstation.com/p/assets/images/images/027/898/039/large/isaac-koerbin-highresscreenshot00012.jpg?1592897194" 
            link="https://www.artstation.com/artwork/5X8R38"    
            />

            <ProjectThumbnail 
            title="Gun Assets - Weapon Plugin" 
            description="Some gun assets for an upcoming weapon plugin in UE4. Ak47, Double Barrel Shotgun, M1911 and Ekol Firat Magnum." 
            src="https://cdnb.artstation.com/p/assets/images/images/024/442/057/large/isaac-koerbin-highresscreenshot00016.jpg?1582441169" 
            link="https://www.artstation.com/artwork/qAW2Na"    
            />

            <ProjectThumbnail 
            title="Environment Art - Jungle" 
            description="A wide collection of jungle and tropical related assets. Made with Speedtree, Substance Designer and rendered in Unreal Engine 4." 
            src="https://cdnb.artstation.com/p/assets/images/images/023/582/603/large/isaac-koerbin-highresscreenshot00000.jpg?1579680737" 
            link="https://www.artstation.com/artwork/nQRlR6"    
            />

            <ProjectThumbnail 
            title="Material - Scan Francisco Tram Road" 
            description="Substance Designer practice, attempting to replicate the tram line roads in San Francisco" 
            src="https://cdnb.artstation.com/p/assets/images/images/022/102/877/large/isaac-koerbin-rendera.jpg?1574121782" 
            link="https://www.artstation.com/artwork/nQXzz6"    
            />
            
            
            <ProjectThumbnail 
            title="UE4 - RVT for fluid interaction" 
            description="A walkthrough on how I used Runtime Virtuals Textures to make fluid interactions." 
            src="https://i.ytimg.com/vi/4T0bAx2dXMw/maxresdefault.jpg" 
            link="https://www.youtube.com/watch?v=4T0bAx2dXMw&ab_channel=RPMonkey"    
            />
            
            <ProjectThumbnail 
            title="UE4 - RVTs for ripple simulation" 
            description="A free project that shows how Runtime Virtual Textures can be used to make ripple simulations." 
            src="https://i.ytimg.com/vi_webp/6kgAE9p-QKs/maxresdefault.webp" 
            link="https://www.youtube.com/watch?v=6kgAE9p-QKs&ab_channel=RPMonkey"    
            />

            <ProjectThumbnail 
            title="UE4 - RVTs for shore waves at angle" 
            description="Using Runtime Virtual Textures I managed to get shorewaves appearing at any angle for landscapes sculpted in Unreal Engine." 
            src="https://img.youtube.com/vi/v3IGy3NroRY/hqdefault.jpg" 
            link="https://www.youtube.com/watch?v=v3IGy3NroRY&ab_channel=RPMonkey"    
            />

            <ProjectThumbnail 
            title="UE4 - RVTs for persistant captures" 
            description="Using Runtime Virtual Textures I managed to it to store data from previous frames. This video gives an explanation of how the technqiue works." 
            src="https://i.ytimg.com/vi/LkQ03MSaDxA/maxresdefault.jpg" 
            link="https://www.youtube.com/watch?v=LkQ03MSaDxA&ab_channel=RPMonkey"    
            />

            <ProjectThumbnail 
            title="UE4 - RVTs for 3D mesh decal blending" 
            description="A technique similiar to the ones used in Far Cry 5, this approach uses a channel in the RVT for height displacement, diffuse and normal transfer into a landscape material." 
            src="https://i.ytimg.com/vi/vvukxPzIYnc/maxresdefault.jpg" 
            link="https://www.youtube.com/watch?v=vvukxPzIYnc&ab_channel=RPMonkey"    
            />

            <ProjectThumbnail 
            title="Game - University Project #1" 
            description="A game created for my 2017 college unit called 'Object Design'. In this unit, we had 8 months to create an art piece that was either fabric, woodwork or a digital piece based off the theme 'Hide and Seek'. I chose to make a game, called Obscurity. " 
            src="https://img.youtube.com/vi/MxXVa1SQpqk/maxresdefault.jpg" 
            link="https://www.youtube.com/watch?v=MxXVa1SQpqk&ab_channel=RPMonkey"    
            />

            <ProjectThumbnail 
            title="Game - University Project #2" 
            description="You must explore the open ocean in search of sunken pirate ships and the loot which they may hold. I was lead artist on this, and first time translating my 3D artwork from Unreal Engine into Unity." 
            src="https://img.itch.zone/aW1hZ2UvNDY4Mzk3LzI1ODg3NjYucG5n/original/%2BTHTjw.png" 
            link="https://samcarey.itch.io/submergence"    
            />

            
        </div>
    )
}

export default Projects