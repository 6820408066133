import './Footer.css';
import { NavLink, Link } from 'react-router-dom';

function Footer() {
    return (
        <>
            <div className="footer">
                <div className="inner_footer">
                <div className="logo_container">
                <Link to="/"><img src="./images/RefinedPoly_Logo_Greyscale.svg" alt="logo"/></Link>
            </div>
            <div className="footer_third">
                <h1>Site</h1>
                <NavLink to="/">Home</NavLink>
                <NavLink to="/products">Products</NavLink>
                <a href="https://rocketpoweredmonkey.blogspot.com/">Blog </a>
                <NavLink to="portfolio">Portfolio</NavLink>
            </div>
            <div className="footer_third">
                <h1>More</h1>
                <NavLink to="/about">About</NavLink>
                <NavLink to="/contact">Contact</NavLink>
            </div>
            <div className="footer_third">
                <h1>Follow Us</h1>
                <li><a href="/instagram"><i className="fab fa-instagram"></i></a></li>
                <li><a href="/facebook"><i className="fab fa-facebook"></i></a></li>
                <li><a href="https://twitter.com/_RPMonkey"><i className="fab fa-twitter"></i></a></li>
                <li><a href="https://www.artstation.com/rpmonkey"><i className="fab fa-artstation"></i></a></li>
                <li><a href="https://www.youtube.com/channel/UCSyuzY_EPjTq1EwUQd6y2Pw"><i className="fab fa-youtube"></i></a></li>
                <li><a href="/email"><i className="fas fa-envelope"></i></a></li>

                <span>
                    RefinedPoly 2020-2020 <br></br>
                    All Rights Reserved.<br></br>
                    Just joking, they are not. 
                </span>
            </div>
                </div>
            </div>
        </>
    )
}

export default Footer;