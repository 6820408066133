import React from 'react'
import './HomePage.css';
import Slider from '../Slider';
import ShopItems from '../shop/ShopItems';

function HomePage() {
    return (
        <>
        <Slider />
        <ShopItems />
        </>
    )
}

export default HomePage
