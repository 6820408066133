import React from 'react'
import './About.css';

function About() {
    return (
        <>
        <h4>Contact</h4>
        <p>lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum</p>
        </>
    )
}

export default About
