import './Navbar.css';
import { NavLink, Link } from 'react-router-dom';

function Navbar() {
    return(
        <>
            <nav>
            <input type="checkbox" id="check"/>
            <label htmlFor="check" className="checkbtn">
                <i className="fas fa-bars"></i>
            </label>
            <div className="logocontainer">
                <Link to="/"><img src="./images/RefinedPoly_Logo.svg" alt="logo"/></Link>
            </div>
            <label className="brandname"><Link to="/">Refined Poly</Link></label>
            <ul>
                <li><NavLink to="/">Home</NavLink></li>
                <li><NavLink to="/products" >Products</NavLink></li>
                <li><a href="https://rocketpoweredmonkey.blogspot.com/">Blog</a></li>
                <li><NavLink to="/portfolio" >Portfolio</NavLink></li>
            </ul>
            </nav>
        </>
    )
}

export default Navbar