import React from 'react'
import './Portfolio.css';
import Projects from '../projects/Projects'

function Portfolio() {
    return (
        <div>
        <div className="about-container" >
            <h1>ABOUT</h1>
            <p></p>
            <text>Hi, my name is Isaac Koerbin and I’m a Technical Artist, Programmer and 3D Artist. </text>
            <p></p>
            <text>I love making interactive effects and pushing graphics to the next level,
             whether it be making foliage move when a player walks through it, or ripple simulations when swimming in the ocean. 
            I love problem solving and thinking outside of the box to create new effects. </text>
            <p></p>
            <text>With a degree in Information and Communication Technology majoring in Games and Creative Technology, 
            I know a vast amount of skills in many different areas of IT, ranging from database planning, implementation, 
            procedural programming to administrative tasks of project management, system development life cycle, 
            and test plans. I have a particular passion for programming graphics, whether it be Graphic User Interfaces in WPF or Android Studio, 
            responsive websites with HTML and CSS, making graphical designs in Photoshop or 3 Dimensional visuals in video games</text>
            <p></p>
            <div className="line-segment"></div>

            <h1>SKILLS</h1>
            <h2>Programming</h2>
            <text>
                <ul>
                    <li>•	C</li>
                    <li>•	C#</li>
                    <li>•	C++</li>
                    <li>•	Python</li>
                    <li>•	Java</li>
                    <li>•	Android Studio (XML & Java)</li>
                    <li>•	HTML</li>
                    <li>•	CSS</li>
                    <li>•	SCSS</li>
                    <li>•	Javascript</li>
                    <li>•	JQuery</li>
                    <li>•	PHP</li>
                    <li>•	React</li>
                </ul>
            </text>

            <div className="line-segment"></div>
            <h2>Art / Technical Art Skills</h2>
            <text>
                <ul>
                    <li>•	PBR Material Authoring in Unreal Engine 4 (Material Editor)</li>
                    <li>•	PBR Material Authoring in Unity 5 (Shader Graph)</li>
                    <li>•	HLSL fragment and vertex shaders</li>
                    <li>•	GLSL fragment and vertex shaders</li>
                    <li>•	Texture Authoring in Substance Designer, Substance Painter, and Bitmap2Material</li>
                    <li>•	C++ plugin creation in Unreal Engine 4</li>
                    <li>•	2D animating using Anima2D in Unity</li>
                    <li>•	Modelling, Unwrapping, Retopology and Texturing in Blender (since 2011)</li>
                    <li>•	Blueprint procedural generators in Unreal Engine 4</li>
                    <li>•	2D drawing in Procreate, Photoshop and Affinity Photo, Affinity Designer</li>
                </ul>
            </text>
            <div className="line-segment"></div>
            <h2>Other Skills</h2>
            <text>
                <ul>
                    <li>•	Project Management</li>
                    <li>•	Familiar with Agile lifecycle</li>
                    <li>•	Familiar with Office 365 and Office applications</li>
                    <li>•	GitHub, Tortoise SVN and GitHub Desktop (working under branches, pushing, pulling, merging, rebasing).</li>
                    <li>•	Database query, insertion and updating through MYSQL.</li>
                </ul>
            </text>
            <div className="line-segment"></div>

            <h1>My Work</h1>
            <text> I regulary post new updates for technical related projects I make on my Youtube Channel:
            <p></p>
            <a href="https://www.youtube.com/channel/UCSyuzY_EPjTq1EwUQd6y2Pw"><i className="fab fa-youtube"></i></a>
            </text>
            
        </div>
        <Projects />
        </div>
        
    )
}

export default Portfolio
