import React from 'react'
import './NavBarFix.css';

function NavBarFix() {
    return (
        <>
            <div className="test">

            </div>
        </>
    )
}

export default NavBarFix
