import React from 'react'
import ShopItem from './ShopItem';
import './ShopItems.css';

function ShopItems() {
    return (
        <div className="container">
            <ShopItem 
            title="UE4 - Basic Nature Pack" 
            description="A basic nature pack that includes a variety of assets to use in your environment." 
            src="https://cdn1.epicgames.com/ue/product/Screenshot/1-1920x1080-3f5a72afb305e09026781f093ed008bd.jpg" 
            link="https://www.unrealengine.com/marketplace/en-US/product/basic-nature-pack"    
            />

            <ShopItem 
            title="UE4 - Colourful WaterPack" 
            description="A small collection of waterpark props" 
            src="https://cdn1.epicgames.com/ue/product/Screenshot/6-1920x1080-47d2387cd0619ecc960697c6c73b71b2.png"
            link="https://www.unrealengine.com/marketplace/en-US/product/colorful-waterpark-pack"   
            />

            <ShopItem 
            title="UE4 - Basic Jungle Pack" 
            description="A wide variety of game ready jungle related assets." 
            src="https://cdnb.artstation.com/p/assets/images/images/023/582/603/large/isaac-koerbin-highresscreenshot00000.jpg?1579680737"
            link="https://www.artstation.com/marketplace/p/D6Gr/basic-jungle-pack-ue4?utm_source=artstation&utm_medium=referral&utm_campaign=homepage&utm_term=marketplace"   
            />

            <ShopItem 
            title="Blender - The Fern Collection" 
            description="A small collection of different fern types, found in Tasmania. Includes 7 different types of ferns, sourced from the Houn pine forests of Tasmania. Packed into a .blend file, using version 2.80" 
            src="https://cdnb.artstation.com/p/marketplace/presentation_assets/000/253/593/large/file.jpg?1576618183%22);%20background-size:%201800px%201013px;%20background-position:%2099.1333%%2047.2072%;" 
            link="https://www.artstation.com/marketplace/p/y5PD/blender-the-fern-collection?utm_source=artstation&utm_medium=referral&utm_campaign=homepage&utm_term=marketplace"  
            />

            <ShopItem 
            title="UE4 - The Fern Collection" 
            description="A small collection of different fern types, found in Tasmania. Includes 6 different types of ferns, sourced from the Houn pine forests of Tasmania. Each fern type has customisable subsurface colors and wind parameters. Uses Unreal Engine 4.24" 
            src="https://cdna.artstation.com/p/marketplace/presentation_assets/000/253/352/large/file.jpg?1576574654" 
            link="https://www.artstation.com/marketplace/p/9kRd/ue4-the-fern-collection?utm_source=artstation&utm_medium=referral&utm_campaign=homepage&utm_term=marketplace"  
            />

            <ShopItem 
            title="Unity - The Fern Collection" 
            description="A small collection of different fern types, found in Tasmania. Includes 7 different types of ferns, sourced from the Houn pine forests of Tasmania. Using Unity 2019.1.6f1" 
            src="https://cdnb.artstation.com/p/marketplace/presentation_assets/000/253/361/large/file.jpg?1576575091" 
            link="https://www.artstation.com/marketplace/p/7J2p/unity-the-fern-collection?utm_source=artstation&utm_medium=referral&utm_campaign=homepage&utm_term=marketplace"  
            />

            <ShopItem 
            title="Textures - The Fern Collection" 
            description="A small collection of different fern types, found in Tasmania. Includes 7 different types of ferns, sourced from the Houn pine forests of Tasmania. This file contains only the edited fern texutres. Transparency is stored in the alpha of the Albedo texture." 
            src="https://cdna.artstation.com/p/marketplace/presentation_assets/000/253/602/large/file.jpg?1576618886" 
            link="https://www.artstation.com/marketplace/p/xpP3/textures-only-the-fern-collection?utm_source=artstation&utm_medium=referral&utm_campaign=homepage&utm_term=marketplace"  
            />

            <ShopItem 
            title="SBS/SBSAR - Debris" 
            description="An industrial concrete substance material. Includes:SBS graph, SBSAR file for customisation, 6 rendered maps (Albedo, Normal, Height, Roughness, Metallic, Ambient Occlusion)." 
            src="https://static-2.gumroad.com/res/gumroad/4594200614112/asset_previews/917578404c9074839c9c4ff0540f2680/retina/DebrisPlaneRender.png" 
            link="https://gum.co/LLQxl"  
            />

            <ShopItem 
            title="SBS/SBSAR - Industrail Concrete" 
            description="An debris generator material. Includes:SBS graph, SBSAR file for customisation, 6 rendered maps (Albedo, Normal, Height, Roughness, Metallic, Ambient Occlusion)." 
            src="https://static-2.gumroad.com/res/gumroad/4594200614112/asset_previews/95428df313539f338ba0f93cf15fd02d/retina/Thumbnail.png"
            link="https://gum.co/kuDJw"   
            />
            
        </div>
    )
}

export default ShopItems
